import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Scrollbars } from 'react-custom-scrollbars';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { FaFacebookF, FaVimeoV, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

import * as URoute from 'util/Route';

const Menu = ({ onClose }) => {
    const classes = useStyles();

    const onClick = () => {
        window.scrollTo(0, 0);
        onClose();
    };

    return (
        <div className={classes.panel}>
            <Scrollbars>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={9} className={classes.colLeft}>
                            <Grid container spacing={2} alignContent='center'>
                                <Grid item className={classes.colMainNav}>
                                    <List>
                                        <ListItem
                                            button
                                            component={Link}
                                            to={URoute.url('home')}
                                            onClick={onClick}>
                                            <ListItemText primary='Home' />
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                            button
                                            component={Link}
                                            to={URoute.url('about')}
                                            onClick={onClick}>
                                            <ListItemText primary='About Us' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component='a'
                                            href={URoute.url('blog')}
                                            onClick={onClick}>
                                            <ListItemText primary='Our News' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={Link}
                                            to={URoute.url('contact')}
                                            onClick={onClick}>
                                            <ListItemText primary='Contact Us' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component='a'
                                            href={URoute.url('matrix')}
                                            onClick={onClick}>
                                            <ListItemText primary='Login' />
                                        </ListItem>

                                        <Divider className={classes.sectorMobile} />
                                        <ListItem
                                            className={classes.sectorMobile}
                                            button
                                            component={Link}
                                            to={URoute.url('ea')}
                                            onClick={onClick}>
                                            <ListItemText primary='Estate Agents & Solicitors' />
                                        </ListItem>
                                        <ListItem
                                            className={classes.sectorMobile}
                                            button
                                            component={Link}
                                            to={URoute.url('dev')}
                                            onClick={onClick}>
                                            <ListItemText primary='Builders & Developers' />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item className={classes.colAgent}>
                                    <List>
                                        <ListItem
                                            button
                                            component={Link}
                                            to={URoute.url('ea')}
                                            onClick={onClick}>
                                            <ListItemText primary='Estate Agents & Solicitors' />
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('eaPhoto')}
                                            onClick={onClick}>
                                            <ListItemText primary='Professional Photography' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('eaFloorplan')}
                                            onClick={onClick}>
                                            <ListItemText primary='2D & 3D Floorplans' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('eaBrochure')}
                                            onClick={onClick}>
                                            <ListItemText primary='Branded Brochures' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('eaVs')}
                                            onClick={onClick}>
                                            <ListItemText primary='Virtual Visualisation' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('eaDrone')}
                                            onClick={onClick}>
                                            <ListItemText primary='Drone Film & Photography' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('eaMicrosite')}
                                            onClick={onClick}>
                                            <ListItemText primary='Multi-Media Microsites' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('eaCgi')}
                                            onClick={onClick}>
                                            <ListItemText primary='CGI Digital Imagery' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('eaCopy')}
                                            onClick={onClick}>
                                            <ListItemText primary='Professional Copywriting' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('eaMarketing')}
                                            onClick={onClick}>
                                            <ListItemText primary='Branding & Marketing' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('eaWalkthrough')}
                                            onClick={onClick}>
                                            <ListItemText primary='Walkthrough Video Tours' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('eaSocial')}
                                            onClick={onClick}>
                                            <ListItemText primary='Social Media Management' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('eaTour')}
                                            onClick={onClick}>
                                            <ListItemText primary='3D Virtual Tours' />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item className={classes.colDeveloper}>
                                    <List>
                                        <ListItem
                                            button
                                            component={Link}
                                            to={URoute.url('dev')}
                                            onClick={onClick}>
                                            <ListItemText primary='Builders & Developers' />
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('devCgi')}
                                            onClick={onClick}>
                                            <ListItemText primary='Hyper-Realistic CGI Imagery' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('devVs')}
                                            onClick={onClick}>
                                            <ListItemText primary='Virtual Visualisation' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('devMap')}
                                            onClick={onClick}>
                                            <ListItemText primary='Plot & Site Maps' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('devPhoto')}
                                            onClick={onClick}>
                                            <ListItemText primary='Property Photography' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('devBrochure')}
                                            onClick={onClick}>
                                            <ListItemText primary='Award Winning Brochures' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('devWalkthrough')}
                                            onClick={onClick}>
                                            <ListItemText primary='Walkthrough Film & Tours' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('devTour')}
                                            onClick={onClick}>
                                            <ListItemText primary='3D Virtual Tours' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('devDrone')}
                                            onClick={onClick}>
                                            <ListItemText primary='Drone Film & Photography' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('devSocial')}
                                            onClick={onClick}>
                                            <ListItemText primary='Social Media Management' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('devMicrosite')}
                                            onClick={onClick}>
                                            <ListItemText primary='Multi-Media Microsites' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('devCopy')}
                                            onClick={onClick}>
                                            <ListItemText primary='Professional Copywriting' />
                                        </ListItem>
                                        <ListItem
                                            button
                                            component={HashLink}
                                            to={URoute.url('devMarketing')}
                                            onClick={onClick}>
                                            <ListItemText primary='Marketing & Advertising' />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={3} className={classes.colRight}>
                            <Grid item>
                                <Typography variant='body1' className={classes.contactDetails}>
                                    Genereal Enquiries
                                    <br />
                                    <a href='tel:01316187702'>0131 618 7702</a>
                                </Typography>
                                <br />
                                <Typography variant='body1' className={classes.contactDetails}>
                                    Shoots & Bookings
                                    <br />
                                    <a href='tel:01316186377'>0131 618 6377</a>
                                </Typography>
                                <br />
                                <Typography variant='body1' className={classes.contactDetails}>
                                    Production Office
                                    <br />
                                    <span>
                                        74a St Stephen Street, Stockbridge, Edinburgh, EH3 5AQ
                                    </span>
                                </Typography>
                                <br />
                                <Typography variant='body1' className={classes.contactDetails}>
                                    Head Office
                                    <br />
                                    <span>
                                        Briglands House, Rumbling Bridge, Kinross-shire, KY13 0PS
                                    </span>
                                </Typography>
                                <br />

                                <Divider />
                                <br />
                                <IconButton
                                    aria-label='Facebook'
                                    color='secondary'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://www.facebook.com/propertystudios'>
                                    <FaFacebookF />
                                </IconButton>
                                <IconButton
                                    aria-label='Instagram'
                                    color='secondary'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://www.instagram.com/propertystudios/'>
                                    <FaInstagram />
                                </IconButton>
                                <IconButton
                                    aria-label='Vimeo'
                                    color='secondary'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://vimeo.com/propertystudios'>
                                    <FaVimeoV />
                                </IconButton>
                                <IconButton
                                    aria-label='LinkedIn'
                                    color='secondary'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://www.linkedin.com/company/propertystudiosltd'>
                                    <FaLinkedinIn />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Scrollbars>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    contactDetails: {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.primary.main,
        '& a': {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        '& span': {
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.text.primary,
        },
    },
    close: {
        position: 'absolute',
        top: 15,
        right: 15,
    },
    container: {
        //height: '100%',
    },
    panel: {
        marginTop: 200,
        height: '100%',
        background: '#fff',
    },
    colLeft: {
        alignItems: 'center',
    },
    colRight: {
        alignItems: 'center',
    },

    colAgent: {
        display: 'none',
    },
    colDeveloper: {
        display: 'none',
    },
    sectorMobile: {
        display: 'flex',
    },
    [theme.breakpoints.up('md')]: {
        colAgent: {
            display: 'block',
        },
        colDeveloper: {
            display: 'block',
        },
        sectorMobile: {
            display: 'none',
        },
    },
    [theme.breakpoints.down('sm')]: {
        panel: {
            marginTop: 120,
        },
        colMainNav: {
            width: '100%',
        },
        colRight: {
            '&>div': {
                paddingRight: 24,
                paddingLeft: 24,
            },
        },
    },
}));

export default Menu;
