import React from 'react';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
//import primary from '@material-ui/core/colors/orange';
//import secondary from '@material-ui/core/colors/grey';

const theme = createTheme({
    palette: {
        //contrastThreshold: 2,
        //type: 'dark',
        primary: {
            //main: primary[400],
            main: '#e4B531',
        },
        secondary: {
            main: '#3b434c',
        },
        text: {
            primary: '#707070',
            secondary: '#262626',
        },
        custom: {
            black: {
                light: '#262626',
            },
            white: {
                light: '#FEFEFE',
            },
        },
    },
    typography: {
        fontFamily: ['Gordita', 'Arial', 'sans-serif'].join(','),
        h1: {
            fontSize: '3.125rem',
        },
        h2: {
            fontSize: '2.5rem',
        },
        h3: {
            fontSize: '2.125rem',
        },
        h4: {
            fontSize: '1.75rem',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                a: {
                    textDecoration: 'none',
                    color: 'rgba(0, 0, 0, 0.87)',
                },
                img: {
                    maxWidth: '100%',
                },
            },
        },
        MuiButton: {
            text: {
                padding: '6px 15px',
            },
            textSizeLarge: {
                padding: '8px 20px',
            },
        },
        MuiContainer: {
            root: {
                height: '100%',
            },
        },
        MuiGrid: {
            container: {
                height: '100%',
            },
        },
    },
});

const Theme = ({ children }) => {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </MuiThemeProvider>
    );
};

export default Theme;
