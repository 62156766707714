import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './Reducer';

const initialState = {};
const middleware = [thunk];
let store = null;

// Only chrome can handle the redux dev tool
if (window.navigator.userAgent.includes('Chrome') && process.env.NODE_ENV === 'development') {
    store = createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(...middleware),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    );
} else {
    store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)));
}

export { store };
