import React from 'react';
import classnames from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';

import { MdMenu } from 'react-icons/md';

import SButton from 'style/Button';
import SUtil from 'style/Util';
import * as URoute from 'util/Route';

import Img from 'view/common/Img';
import Menu from './Menu';

const Header = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const [scrollPos, setScrollPos] = React.useState(2);

    const onCLickLogo = () => {
        history.push(URoute.url('home'));
    };

    const handleScrollPos = () => {
        setScrollPos(window.pageYOffset);
    };

    React.useEffect(() => {
        const onKeyPress = (key) => {
            const targetKey = 27; //ESC
            if (key.which === targetKey) {
                setOpen(!open);
            }
        };
        window.addEventListener('scroll', handleScrollPos, { passive: true });
        window.addEventListener('keydown', onKeyPress);
        return () => {
            window.removeEventListener('scroll', handleScrollPos);
            window.removeEventListener('keydown', onKeyPress);
        };
    }, [location.pathname, open]);

    return (
        <React.Fragment>
            <div
                className={classnames(
                    classes.header,
                    scrollPos <= 1 && classes.headerTop,
                    open && classes.menuOpen
                )}>
                <div className={classes.headerUnderlay} />
                <div className={classes.headerContent}>
                    <Grid container>
                        <Grid item xs className={classes.headerLeft}>
                            <div
                                className={classes.logoContainer}
                                onClick={onCLickLogo}
                                aria-label='Home'>
                                <Img
                                    file='logo/logo-dk.png'
                                    alt='Property Studios'
                                    className={classes.logo}
                                />
                            </div>
                        </Grid>
                        <Grid item xs className={classes.headerRight}>
                            <Button
                                className={classes.headerRightBtn}
                                color='secondary'
                                component={Link}
                                to={URoute.url('home')}
                                onClick={() => setOpen(false)}>
                                Home
                            </Button>
                            <Button
                                className={classes.headerRightBtn}
                                color='secondary'
                                component={Link}
                                to={URoute.url('about')}
                                onClick={() => setOpen(false)}>
                                About
                            </Button>
                            <Button
                                className={classes.headerRightBtn}
                                color='secondary'
                                href={URoute.url('blog')}
                                onClick={() => setOpen(false)}>
                                News
                            </Button>
                            <Button
                                className={classes.headerRightBtn}
                                color='secondary'
                                component={Link}
                                to={URoute.url('contact')}
                                onClick={() => setOpen(false)}>
                                Contact
                            </Button>
                            <Button
                                className={classes.headerRightBtn}
                                color='secondary'
                                href={URoute.url('matrix')}
                                onClick={() => setOpen(false)}>
                                Login
                            </Button>
                            <IconButton
                                aria-label='Toggle Menu'
                                color='secondary'
                                className={classes.menuToggle}
                                onClick={() => setOpen(!open)}>
                                <MdMenu />
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Drawer
                className={classes.menu}
                anchor='top'
                open={open}
                onClose={() => setOpen(false)}>
                <Menu onClose={() => setOpen(false)} />
            </Drawer>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    btnLogin: {
        ...SButton(theme).white,
        lineHeight: 1.2,
    },
    header: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        padding: '5px 0',
        transition: 'padding 0.8s ease-in-out, top 0.4s ease-in-out',
        zIndex: 1400,
    },
    headerLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    headerContent: {
        position: 'relative',
        padding: '0 15px',
    },
    headerRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    headerRightBtn: {
        display: 'none',
    },
    headerUnderlay: {
        ...SUtil(theme).fill,
        background: '#fff',
        transition: 'opacity 0.3s ease-in',
        opacity: 1,
        boxShadow: '0px 5px 5px -2px rgba(0,0,0,0.5)',
    },
    logoContainer: {
        ...SUtil(theme).flexed,
        padding: 10,
        transition: 'all 0.5s ease-in-out',
    },
    logo: {
        maxHeight: 40,
        cursor: 'pointer',
        transition: 'all 0.5s ease-in-out',
    },
    menu: {
        '& .MuiPaper-root': {
            height: '100%',
        },
    },
    menuToggle: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.h3.fontSize,
        transition: 'all 0.5s ease-in-out',
    },
    headerTop: {
        padding: '10px 0',
        '& .MuiButton-root': {
            lineHeight: 1.75,
        },
        '& $logoContainer': {
            '& $logo': {
                maxHeight: 50,
            },
        },
        '& $menuToggle': {
            fontSize: theme.typography.h1.fontSize,
        },
    },
    menuOpen: {
        top: 0,
        padding: '30px 0',
        '& .MuiButton-root': {
            lineHeight: 1.75,
        },
        '& $logoContainer': {
            padding: 30,
            '& $logo': {
                maxHeight: 80,
            },
        },
        '& $menuToggle': {
            fontSize: theme.typography.h1.fontSize,
        },
        '& $headerUnderlay': {
            opacity: 0,
        },
    },

    [theme.breakpoints.up('md')]: {
        headerRightBtn: {
            display: 'flex',
        },
    },
    [theme.breakpoints.down('md')]: {
        headerTop: {
            padding: '5px 0',
            '& $logoContainer': {
                '& $logo': {
                    maxHeight: 40,
                },
            },
            '& $menuToggle': {
                fontSize: theme.typography.h3.fontSize,
            },
        },
    },
    [theme.breakpoints.down('sm')]: {
        menuOpen: {
            padding: '10px 0',
            '& $logoContainer': {
                padding: 10,
                '& $logo': {
                    maxHeight: 60,
                },
            },
        },
    },
}));

export default Header;
