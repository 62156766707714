import { CLEAR_ERROR, SET_ERROR } from 'store/Types';

const initialState = {};

export default function ErrorReducer(state = initialState, action) {
    switch (action.type) {
        case CLEAR_ERROR:
            return {};
        case SET_ERROR:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
