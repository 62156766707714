import { CLEAR_ROUTE, MERGE_ROUTE, SET_ROUTE } from 'store/Types';
import * as R from 'ramda';

const initialState = {
    title: 'Property Studios: Creative Marketing Agency, Scotland',
    desc: 'Property Studios is a Marketing and Design Agency that aids the the Housing and Building Industry with their Branding and Property Sales Material.',
    canonical: 'https://www.propertystudios.co.uk/',
    loading: true,
};

export default function RouteReducer(state = initialState, action) {
    switch (action.type) {
        case CLEAR_ROUTE:
            return R.clone(initialState);
        case MERGE_ROUTE:
            return R.mergeDeepRight(state, action.payload);
        case SET_ROUTE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
