const config = {
    path: {
        img: '/img/',
    },
    version: '1.3',
    company: {
        name: 'Property Studios',
        head: {
            address: 'Briglands House, Rumbling Bridge, Kinross-shire, KY13 0PS',
            lat: 56.17859,
            lng: -3.57213,
        },
        production: {
            address: '74a St Stephen Street, Stockbridge, Edinburgh, EH3 5AQ',
            lat: 55.957439,
            lng: -3.20755,
        },
    },
    google: {
        apiKey: 'AIzaSyAmin620zd9v4Ep2dCL1Z9XKEeChbfdmyg',
        analytics: 'G-1F9WNZDFJV',
        recaptcha: {
            publicKey: '6LdATlgcAAAAAFOc0vv5QJ6zfyOvSNZjTyBJ57Rn',
        },
        map: {
            options: {
                zoom: 7,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                panControl: false,
                navigationControl: false,
                streetViewControl: false,
                scrollwheel: false,

                styles: [
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#e9e9e9',
                            },
                            {
                                lightness: 17,
                            },
                        ],
                    },
                    {
                        featureType: 'landscape',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#f5f5f5',
                            },
                            {
                                lightness: 20,
                            },
                        ],
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.fill',
                        stylers: [
                            {
                                color: '#ffffff',
                            },
                            {
                                lightness: 17,
                            },
                        ],
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [
                            {
                                color: '#ffffff',
                            },
                            {
                                lightness: 29,
                            },
                            {
                                weight: 0.2,
                            },
                        ],
                    },
                    {
                        featureType: 'road.arterial',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#ffffff',
                            },
                            {
                                lightness: 18,
                            },
                        ],
                    },
                    {
                        featureType: 'road.local',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#ffffff',
                            },
                            {
                                lightness: 16,
                            },
                        ],
                    },
                    {
                        featureType: 'poi',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#f5f5f5',
                            },
                            {
                                lightness: 21,
                            },
                        ],
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#dedede',
                            },
                            {
                                lightness: 21,
                            },
                        ],
                    },
                    {
                        elementType: 'labels.text.stroke',
                        stylers: [
                            {
                                visibility: 'on',
                            },
                            {
                                color: '#ffffff',
                            },
                            {
                                lightness: 16,
                            },
                        ],
                    },
                    {
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                saturation: 36,
                            },
                            {
                                color: '#333333',
                            },
                            {
                                lightness: 40,
                            },
                        ],
                    },
                    {
                        elementType: 'labels.icon',
                        stylers: [
                            {
                                visibility: 'off',
                            },
                        ],
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#f2f2f2',
                            },
                            {
                                lightness: 19,
                            },
                        ],
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry.fill',
                        stylers: [
                            {
                                color: '#fefefe',
                            },
                            {
                                lightness: 20,
                            },
                        ],
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry.stroke',
                        stylers: [
                            {
                                color: '#fefefe',
                            },
                            {
                                lightness: 17,
                            },
                            {
                                weight: 1.2,
                            },
                        ],
                    },
                ],
            },
        },
    },
    url: {
        api: 'https://matrix.propertystudios.co.uk/api/',
        img: '/img/',
        site: 'https://www.propertystudios.co.uk',
    },
};

if (process.env.NODE_ENV === 'development') {
    config.google.apiKey = 'AIzaSyCJzzCI2jXQrIENxgv2PEXErdbJIlxdzHY';
    config.google.analytics = '';
    config.google.recaptcha.publicKey = '6LfPcuMSAAAAANNlpHenOty0vFfFI8PlvIR5agBX';
    config.url.api = 'http://localhost/property-studios/matrix/api/';
    config.url.img = '/img/';
    config.url.site = 'http://localhost:3010';
}

export default config;
