import React from 'react';
import { Link } from 'react-router-dom';

import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { FaFacebookF, FaVimeoV, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

import Img from 'view/common/Img';
import * as URoute from 'util/Route';

const Footer = () => {
    const classes = useStyles();
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

    // const toTop = () => {
    //     window.scrollTo({
    //         top: 0,
    //         left: 0,
    //         behavior: 'smooth',
    //     });
    // };

    return (
        <React.Fragment>
            <div className={classes.footer}>
                <Container maxWidth='lg' className={classes.footerContainner}>
                    <Grid container direction={isMd ? 'row' : 'column-reverse'} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant='body1' className={classes.contactDetails}>
                                General Enquiries
                                <br />
                                <a href='tel:01316187702'>0131 618 7702</a>
                            </Typography>
                            <br />
                            <Typography variant='body1' className={classes.contactDetails}>
                                Shoots & Bookings
                                <br />
                                <a href='tel:01316186377'>0131 618 6377</a>
                            </Typography>
                            <br />
                            <Typography variant='body1' className={classes.contactDetails}>
                                Production Office
                                <br />
                                <span>74a St Stephen Street, Stockbridge, Edinburgh, EH3 5AQ</span>
                            </Typography>
                            <br />
                            <Typography variant='body1' className={classes.contactDetails}>
                                Head Office
                                <br />
                                <span>
                                    Briglands House, Rumbling Bridge, Kinross-shire, KY13 0PS
                                </span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.footerRight}>
                            <div>
                                <IconButton
                                    aria-label='Facebook'
                                    className={classes.social}
                                    color='secondary'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://www.facebook.com/propertystudios'>
                                    <FaFacebookF />
                                </IconButton>
                                <IconButton
                                    aria-label='Instagram'
                                    className={classes.social}
                                    color='secondary'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://www.instagram.com/propertystudios/'>
                                    <FaInstagram />
                                </IconButton>
                                <IconButton
                                    aria-label='Vimeo'
                                    className={classes.social}
                                    color='secondary'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://vimeo.com/propertystudios'>
                                    <FaVimeoV />
                                </IconButton>
                                <IconButton
                                    aria-label='LinkedIn'
                                    className={classes.social}
                                    color='secondary'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://www.linkedin.com/company/propertystudiosltd'>
                                    <FaLinkedinIn />
                                </IconButton>
                            </div>
                            <br />
                            <br />
                            <Img
                                file='/logo/logo-li-portrait.png'
                                alt='Property Studios'
                                className={classes.logo}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.subFooter}>
                <Container maxWidth='lg'>
                    <Typography variant='body2' className={classes.copyright}>
                        &copy; {new Date().getFullYear()} Property Studios Ltd, All Rights Reserved.
                        <br />
                        <Link to={URoute.url('terms')}>Terms</Link>
                        {' | '}
                        <Link to={URoute.url('privacy')}>Privacy</Link>
                    </Typography>
                </Container>
            </div>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    logo: {
        maxHeight: 150,
    },
    contactDetails: {
        fontWeight: theme.typography.fontWeightLight,
        color: theme.palette.primary.main,
        '& a': {
            color: theme.palette.grey[100],
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        '& span': {
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.grey[100],
        },
    },
    copyright: {
        fontSize: '0.6875rem',
        '& a': {
            color: theme.palette.grey[100],
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
    },
    footer: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.grey[100],
        padding: '50px 0',
    },
    footerContainner: {
        position: 'relative',
    },
    footerRight: {
        textAlign: 'right',
    },
    social: {
        backgroundColor: theme.palette.primary.main + '!important',
        margin: '0 5px',
        transition: 'color 0.3s ease-in',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.grey[100],
        },
    },
    subFooter: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.grey[100],
        padding: '20px 0',
        textAlign: 'center',
    },
    toTop: {
        color: theme.palette.grey[100],
        textAlign: 'center',
        position: 'absolute',
        bottom: 10,
        right: 0,
        lineHeight: 1.1,
        '& span': {
            display: 'block',
        },
        '& svg': {
            fontSize: theme.typography.h3.fontSize,
        },
    },

    [theme.breakpoints.down('sm')]: {
        contactDetails: {
            textAlign: 'center',
        },
        copyright: {
            textAlign: 'center',
        },
        footerRight: {
            textAlign: 'center',
        },
        toTop: {
            display: 'none',
        },
    },
}));

export default Footer;
