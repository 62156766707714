import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const Layout = () => {
    const classes = useStyles();
    return <div className={classes.loading}>Loading</div>;
};

const useStyles = makeStyles((theme) => ({
    loading: {},
}));

export default Layout;
