import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import Footer from './Footer';
import Header from './Header';
import Theme from './Theme';
import * as URoute from 'util/Route';

import Config from 'config/Config';

const Loading = ({ children }) => {
    const classes = useStyles();
    const location = useLocation();
    const state = useSelector((state) => ({
        title: state.route.title,
        description: state.route.description,
        img: state.route.img,
    }));

    React.useEffect(() => {
        URoute.setMetaByRef(location.pathname);
    }, [location.pathname]);

    return (
        <Theme>
            <Helmet>
                <title>{state.title}</title>
                <meta name='description' content={state.description} />

                <meta property='og:title' content={state.title} />
                <meta property='og:description' content={state.description} />
                <meta property='canonical' content={window.location.href} />
                <meta property='og:url' content={window.location.href} />
                <meta property='og:img' content={Config.url.site + Config.url.img + state.img} />
            </Helmet>
            <div
                className={classNames(
                    classes.container,
                    location.pathname !== '/' && classes.containerPassing
                )}>
                <Header />
                <div className={classes.containerInner}>{children}</div>
                <Footer />
            </div>
        </Theme>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        minWidth: 320,
        overflow: 'hidden',
        position: 'relative',
    },
    containerPassing: {
        paddingTop: 70,
    },
    containerInner: {
        minHeight: '100vh',
        position: 'relative',
    },
}));

export default Loading;
