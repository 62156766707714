const styles = (theme) => ({
    containedWhite: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[900],
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
    },
    outlinedWhite: {
        borderColor: theme.palette.grey[100],
        color: theme.palette.grey[100],
        '&:hover, &:focus': {
            borderColor: theme.palette.primary.main,
        },
    },
});

export default styles;
