import React from 'react';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import Config from 'config/Config';

const Img = ({ file, className, alt, props = {} }) => {
    const classes = useStyles();
    const path = Config.url.img + file + '?v=' + Config.version;

    const classNames = [classes.img];
    if (typeof className !== 'undefined') {
        classNames.push(className);
    }

    return (
        <img
            src={path}
            alt={typeof alt !== 'undefined' ? alt : path}
            className={classnames(classNames)}
            {...props}
        />
    );
};

const useStyles = makeStyles((theme) => ({
    img: {
        maxWidth: '100%',
    },
}));

export default Img;
