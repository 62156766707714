import { fade } from '@material-ui/core/styles/colorManipulator';

import Config from 'config/Config';

const styles = (theme) => ({
    aspect: (x = 16, y = 9) => ({
        position: 'relative',
        '&:before': {
            display: 'block',
            content: '""',
            width: '100%',
            paddingTop: (y / x) * 100 + '%',
        },
    }),
    bgImage: ({ url, size = 'cover', pos = 'center center' }) => ({
        backgroundColor: 'transparent',
        backgroundImage: 'url("' + Config.url.img + url + '")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: pos,
        backgroundSize: size,
    }),
    fill: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    flexed: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rgba: (color, opacity = 1) => fade(color, opacity),
    sep: {
        borderBottom: '1px dashed ' + theme.palette.grey[400],
        paddingTop: 50,
        marginBottom: 50,
    },
});

export default styles;
