import { CLEAR_ROUTE, MERGE_ROUTE, SET_ROUTE } from 'store/Types';

export const clear = () => {
    return {
        type: CLEAR_ROUTE,
    };
};

export const merge = (data) => {
    return {
        type: MERGE_ROUTE,
        payload: data,
    };
};

export const set = (data) => {
    return {
        type: SET_ROUTE,
        payload: data,
    };
};
